import { Outlet } from "@solidjs/router";
import { Atom, Cmp, Ctrl, state } from ":mods";
import { DashboardViewProps } from "../models";
import { For, Match, Show, Switch, batch, createEffect, createMemo, splitProps } from "solid-js";
import { SideLayer } from "../components";
import { $dashboard, $dashboard_active_layer_item_idx, _dashboard_actions } from "../store";

export function Dashboard(props: DashboardViewProps) {
  const [local, others] = splitProps(props, ["sideNav", "topNav"]);
  // createEffect(() => {
  //   console.log("effect from dashboard :: ", $dashboard.value.breadcrumb);
  // });
  return (
    <main class={`flex flex-col w-full h-full`}>
      <Ctrl.Nav.Top {...local.topNav} class={`nav-top ${local.topNav?.class ?? ""}`} />
      <div class="flex flex-row w-full h-full trim$h">
        <Ctrl.Nav.Side
          {...local.sideNav}
          status={$dashboard.value.sideNavStatus}
          items={local.sideNav?.items}
          layer={$dashboard.value.layer && <SideLayer {...$dashboard.value.layer} base={local?.sideNav?.base} />}
        />
        <section class={`flex flex-row w-full h-full bg!paper text#p ${!$dashboard.value.overflow ? " trim$ " : " "}`}>
          <div class="flex flex-col w-full h-full px-4 pt-4 pb-2 !overflow-x-hidden">
            <div class="flex flex-col w-full h-fit font-light">
              <Show when={$dashboard.value.title}>
                <Show when={typeof $dashboard.value.title === "string"} fallback={($dashboard.value.title as any)()}>
                  <h1 class="text-1.4rem capitalize font-bold">{$dashboard.value.title as string}</h1>
                </Show>
              </Show>
              {/* breadcrumb */}
              <div class="flex flex-row items-center justify-between">
                <div>
                  {/* no <For /> because we want to refresh the whole array */}
                  {$dashboard.value?.breadcrumb?.map((item, index) => {
                    const is_last = index >= $dashboard.value.breadcrumb.length - 1;
                    return (
                      <>
                        <span
                          onClick={
                            !is_last &&
                            (() => {
                              Atom.Route.actions.navigateHref(item.to, {
                                afterNavigate: $dashboard.value.layer?.events?.afterBackTo,
                              });
                            })
                          }
                          class={`capitalize text-sm ${is_last ? "font-bold" : "underline cursor-pointer"} `}
                        >
                          {item.title}
                        </span>
                        <Show when={!is_last}>
                          <span class="px-2 no-underline">{">"}</span>
                        </Show>
                      </>
                    );
                  })}
                </div>
              </div>
              {$dashboard.value.titleBorder && <div class="w-full h-2px bg#p" />}
            </div>
            <div class={`flex flex-col w-full h-full !overflow-x-hidden scrollbar`}>
              <Outlet />
            </div>
          </div>
          <div class="flex flex-col w-15vw min-w-15vw h-full text-16px !overflow-y-auto !overflow-x-hidden px-0.5">
            <For each={$dashboard.value.sideInfo}>
              {(item, index) => {
                return (
                  <>
                    <Show when={item.title}>
                      <h3 class="font-bold capitalize my-2">{item.title}</h3>
                    </Show>
                    {item.element()}
                  </>
                );
              }}
            </For>
          </div>
        </section>
      </div>
      <footer class="flex flex-row w-full gap-x-3 h-50px min-h-50px max-h-50px text-0.7rem items-center justify-center">
        <span>©Museums Future 2023</span>
        <span>|</span>
        <Cmp.I18n.LanguageSelector />
      </footer>
    </main>
  );
}
